<template>
  <div class="container bgf">
    <template v-if="$route.query.status === 'success'">
      <div class="result">
        <div class="result-icon">
          <van-icon name="success" />
        </div>
        <div class="result-title">
          支付成功
        </div>
      </div>
      <div class="action">
        <van-button plain size="small" type="primary" to="/home">返回首页</van-button>
        <van-button size="small" type="primary" :to="'/order-detail?order_id='+$route.query.order_id">查看订单</van-button>
      </div>
    </template>
    <template v-else>
      <div class="result result-fail">
        <div class="result-icon">
          <van-icon name="fail" />
        </div>
        <div class="result-title">
          支付失败
        </div>
      </div>
      <div class="action">
        <van-button plain size="small" type="primary" to="/home">返回首页</van-button>
        <van-button size="small" type="primary" to="/cart">重新尝试</van-button>
      </div>
    </template>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'

export default {
  name: 'OrderResult',
  mixins: [PageMixin],
  data() {
    return {
      status: 'fail' // success,fail
    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang="less" scoped>

  .container{
    padding: 20px;
  }
  .result{
    &-hd{

    }
    &-icon{
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      font-size: 60px;
      color: #fff;
      background: #70c67d;
      border-radius: 50%;
      margin-bottom: 15px;
    }
    &-title{
      font-size: 18px;
      font-weight: bold;
      color: #505050;
      margin-bottom: 20px;
    }
  }
  .result-fail{
    .result-icon{
      background: #ed6772;
    }
  }
  .action{
    width: 100%;
    display:flex;
    justify-content: space-around;
    align-items: center;
  }
  .container{
    height: 100%;
    flex-direction: column;
    display:flex;
    justify-content: center;
    align-items: center;
  }
</style>

